import React from "react"
import { graphql } from "gatsby"
import PageTitle from "../components/PageTitle"
import TwoColSection from "../components/TwoColSection"
import OneColSection from "../components/OneColSection"
import ContactForm from "../components/contactForm/ContactForm"
import Layout from "../components/Layout"
import { StyledAddress } from "../components/common/StyledAddress"
import Address from "../components/Address"
// import ContactFormSubsti from "../components/contactForm/ContactFormSubsti"

export const query = graphql`
  {
    prismic {
      allContact_forms(lang: "de-ch") {
        edges {
          node {
            form_description
          }
        }
      }
      allKontakts(lang: "de-ch") {
        edges {
          node {
            seo_title
            seo_description
            title
            text
          }
        }
      }
    }
  }
`
function Contact(props) {
  const seoData = props.data.prismic.allKontakts.edges[0].node

  let seoTitle = ""

  // Set default values if null
  if (seoData.seo_title) {
    seoTitle = seoData.seo_title
  } else {
    seoTitle = "Pachamama Festival" + " | " + seoData.title[0].text
  }

  const seoDescription = seoData.seo_description

  return (
    <Layout seoTitle={seoTitle} seoDescription={seoDescription}>
      <PageTitle
        pageTitle={props.data.prismic.allKontakts.edges[0].node.title}
      />
      <OneColSection
        content={props.data.prismic.allKontakts.edges[0].node.text}
      />
      <TwoColSection
        // colleft={<ContactFormSubsti />}
        colleft={<ContactForm />}
        colright={
          <StyledAddress pageaddress>
            <Address pathname="/kontakt" />
          </StyledAddress>
        }
      />
    </Layout>
  )
}

export default Contact
